.root-container {
  display: flex;
  position: relative;
  z-index: 0; /* Establish a new stacking context */
  width: 100%; /* Full width of the viewport */
  height: 100%; /* Full height of the viewport */
}

.appbar {
  position: absolute;
  width: 100%; /* Full width when drawer is closed */
  height: 50px;
  margin-left: 0; /* No margin when drawer is closed */
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1), margin 225ms cubic-bezier(0.4, 0, 0.6, 1); /* Leaving Screen */
}

/* AppBar when drawer is open */
.appbar.open {
  margin-left: 240px; /* Assuming drawerWidth is 240px */
  width: calc(100% - 240px); /* Adjust width when drawer is open */
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1), margin 225ms cubic-bezier(0.4, 0, 0.6, 1); /* Entering Screen */
}

.toolbar {
  flex: 1; /* Takes up only necessary space */
  min-width: 0; /* Prevents overflow */
}

.appbar-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #03045e;
}

.auth {
  flex: none; /* Does not grow or shrink */
  color: white;
}

/* Base styles for the Drawer */
.mui-drawer-paper {
  white-space: nowrap;
  box-sizing: border-box;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1); /* Standard easing */
  overflow-x: hidden; /* Default to hidden */
}

/* Styles when the drawer is open */
.mui-drawer-paper.open {
  width: 240px; /* Example width when open */
}

/* Styles when the drawer is closed */
.mui-drawer-paper.closed {
  width: 56px; /* Example width when closed, can adjust for responsiveness */
}

@media (min-width: 600px) {
  .mui-drawer-paper.closed {
    width: 64px; /* Adjusted width for larger screens when closed */
  }
}

.dashboard-box-container {
  background-color: rgb(242, 241, 241);
  flex-grow: 1;
  height: calc(100vh - 100px);
  overflow: auto;
  margin-top: 50px;
  padding: 20px;
}

.dashboard-box-container.open {
  margin-left: 240px;
}

.dashboard-box-container.closed {
  margin-left: 56px;
}

.single-chip-container {
  display: flex;
  flex-direction: column;
  height: 85vh;
}

.single-chip-view-grid {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5px;
}

.sub-page-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  background-color: white;
}

.drawer-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
  height: 100%;
}

.drawer-content-container.loading {
  justify-content: center;
}

.sub-column-table-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 94%;
}

